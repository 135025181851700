<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 1172.7 116.8" preserveAspectRatio>
<g fill="currentColor">
  <polygon points="517.8,23.1 517.8,113.5 537.3,113.5 537.3,46.9 595.9,113.5 617.6,113.5 617.6,23.1 598,23.1
    598,89.5 539.6,23.1   "/>
  <path d="M447.2,101.6c-20.7,0-34.6-13.4-34.6-33.3c0-19.5,14.2-33.1,34.6-33.1c20.6,0,34.5,13.3,34.5,33.1
    C481.6,87.9,467.5,101.6,447.2,101.6 M488.5,33.4c-9.7-8.8-24-13.4-41.3-13.4c-34.3,0-55.7,18.5-55.7,48.2c0,14.5,5,26.6,14.3,35.1
    c9.7,8.8,24,13.5,41.3,13.5c34.3,0,55.5-18.6,55.5-48.6C502.7,53.8,497.8,41.8,488.5,33.4"/>
  <path d="M168.4,79l18.1-39.6L204.3,79H168.4z M174.6,23.1l-43.7,90.4h21.7l8.9-19.3h49.7l8.6,19.3h22.3l-43.7-90.4
    H174.6z"/>
  <path d="M337,59.9h-0.2C321.9,58,309,56.4,309,46.6c0-7.6,8.2-12,22.6-12c20.8,0,25.1,8.8,25.8,13.3h20.4
    c-2.3-18.6-17.8-28-46.3-28c-12.4,0-22.8,2.3-30.3,6.5c-8.4,4.9-12.7,12.1-12.7,21.6c0,22.4,22.2,25.3,41.8,27.8l0.9,0.1
    c15.2,2,28.3,3.7,28.3,13.3c0,8.1-8.8,12.8-24.3,12.8c-22.7,0-28.6-8.7-29.3-16.3h-20.5c0.8,20.3,17.6,31,48.4,31
    c17.2,0,46-3.8,46-29C379.9,65.2,357.1,62.4,337,59.9"/>
  <polygon points="251.1,23.1 271.6,23.1 271.6,113.5 251.1,113.5  "/>
  <polygon points="89.6,23.1 61.2,94.3 32.2,23.1 0,23.1 0,113.5 19.6,113.5 19.6,39.5 19.8,39.4 50.8,113.5 71.1,113.5
    101.8,39.9 102,39.9 101.9,113.5 121.6,113.5 121.6,23.1  "/>
  <polygon points="940,23.1 960.4,23.1 960.4,113.5 940,113.5  "/>
  <path d="M869.9,101.6c-20.7,0-34.6-13.4-34.6-33.3c0-19.5,14.2-33.1,34.6-33.1c20.6,0,34.4,13.3,34.4,33.1
    C904.3,87.9,890.2,101.6,869.9,101.6 M911.2,33.4c-9.7-8.8-24-13.4-41.3-13.4c-34.3,0-55.7,18.5-55.7,48.2c0,14.5,5,26.6,14.3,35.1
    c9.7,8.8,24,13.5,41.3,13.5c34.3,0,55.5-18.6,55.5-48.6C925.4,53.8,920.5,41.8,911.2,33.4"/>
  <polygon points="1086,23.1 1086,113.5 1172.7,113.5 1172.7,98.6 1106.4,98.6 1106.4,75.8 1168,75.8 1168,60.8
    1106.4,60.8 1106.4,38 1170.1,38 1170.1,23.1   "/>
  <path d="M1033.9,63.6h-37.6V37.9h37.6c4.7,0,15.8,0,15.8,12.9S1038.7,63.6,1033.9,63.6 M1048.6,76.8
    c15.7-3.2,22.7-11.4,22.7-26.4c0-17.1-11.6-27.3-31.1-27.3h-64.3v90.4h20.4V78.4h29.6l24.3,35.1h24.3l-27.2-36.4L1048.6,76.8
    L1048.6,76.8z"/>
  <polygon points="781,39.9 780.9,113.5 800.6,113.5 800.6,23.1 768.6,23.1 740.2,94.3 711.2,23.1 679,23.1 679,113.5
    698.6,113.5 698.6,39.5 698.8,39.4 729.8,113.5 750.1,113.5 780.8,39.9  "/>
  <polygon points="1117.4,14.4 1130,0 1152.7,0 1138.5,14.5  "/>
</g>
</svg>
</template>

<script>
export default {
  name: 'SVG-Logo'
}
</script>

<style>
</style>
